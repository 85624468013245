<template>
  <div>
    <!-- start 顶部 -->
    <div class="top">
      <!-- 切换卡类型 -->
      <el-radio-group v-model="natureNo" size="mini" @change="onChangeCardType">
        <el-radio-button
          v-for="item in cardNatureList"
          :key="item.NatureNo"
          :label="item.NatureNo"
          >{{ item.NatureName }}</el-radio-button
        >
      </el-radio-group>

      <!-- 右侧搜索栏、新增会员按钮 -->
      <div class="right">
        <el-input
          placeholder="请输入内容"
          v-model="searchText"
          size="mini"
        ></el-input>
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
        <el-button
          @click="onAddCard"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          >新增会员卡</el-button
        >
        <el-button
          v-show="authType == 'H'"
          type="success"
          size="mini"
          @click="onShowRelate"
          >同步会员卡</el-button
        >
      </div>
    </div>
    <!-- end 顶部 -->

    <!-- start 数据表格 -->
    <el-table
      v-loading="loading"
      style="width: 100%"
      :data="tableData"
      :max-height="tableMaxHeight"
    >
      <el-table-column width="60" fixed="left">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            v-model="isActiveCheck"
            :checked="allCheck"
            :indeterminate="indeterminate"
            @change="onSelectChange('all', tableData)"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            :value="scope.row.isCheck"
            :checked="scope.row.isCheck"
            :indeterminate="false"
            @change="onSelectChange('', scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="会员卡" width="280" fixed="left">
        <template slot-scope="scope">
          <div class="image-text">
            <div class="el-image">
              <img src="@/assets/img/gold.jpg" />
            </div>
            <div class="text">
              <div>
                <span>{{ scope.row.CardName }}</span>
              </div>
              <div class="size">卡号:{{ scope.row.CardNo }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="CostPrice"
        label="售价"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="KeepPrice"
        label="开卡储值金额"
        width="120"
      ></el-table-column>

      <!-- 不同卡类型的子项目 -->
      <template v-if="natureNo === '0002'">
        <!-- 疗程卡 -->
        <el-table-column label="套卡项目" width="120">
          <template slot-scope="scope">
            <DiscPopover
              ref="discPopover"
              :width="'1000'"
              :cardSettingList="cardSettingList"
              :cardGuid="scope.row.CardGuid"
              type="pack_item"
            ></DiscPopover>
          </template>
        </el-table-column>
        <!-- 这里做一个空table-column是因为el-table组件是根据table有几列渲染对应数据的，
        table的数据动态变化，如果本来有10列你变化成11或9列，el-table会渲染异常，导致“操作”列
        里面的按钮显示不出来，后来实践只要table-column列数一致，就算其中有一个列是空且宽度为0
        它也能够正常渲染其他的数据不影响整体，故这里插入了一条空的并且width为0的table-column -->
        <el-table-column width="0">
          <template>
            <el-popover>
              <div></div>
            </el-popover>
          </template>
        </el-table-column>
      </template>

      <template v-else>
        <!-- 储值卡、附卡 -->
        <el-table-column label="整体折扣" width="120">
          <template slot-scope="scope">
            <DiscPopover
              ref="discPopover"
              :cardSettingList="cardSettingList"
              :cardGuid="scope.row.CardGuid"
              type="disc_class"
            ></DiscPopover>
          </template>
        </el-table-column>
        <el-table-column label="项目折扣" width="120">
          <template slot-scope="scope">
            <DiscPopover
              ref="discPopover"
              :width="'800'"
              :cardSettingList="cardSettingList"
              :cardGuid="scope.row.CardGuid"
              type="disc_item"
            ></DiscPopover>
          </template>
        </el-table-column>
      </template>

      <!-- <template v-else>
        <el-table-column label="指定项目">
          <template>
            <el-popover
              placement="right"
              width="400"
              trigger="hover"
              
            >
              <div slot="reference" style="color: #20a0ff">
                <span>更多</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="折扣设置">
          <template>
            <el-popover
              placement="right"
              width="400"
              trigger="hover"
              
            >
              <div slot="reference" style="color: #20a0ff">
                <span>更多</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="赠送金额">
          <template>
            <el-popover
              placement="right"
              width="400"
              trigger="hover"
              
            >
              <div slot="reference" style="color: #20a0ff">
                <span>更多</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </template> -->

      <el-table-column
        prop="KindName"
        label="分类"
        width="120"
      ></el-table-column>
      <el-table-column label="有效期限" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.DateLimit || "无限期" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="DateUnitsText"
        label="期限单位"
        width="120"
      ></el-table-column>
      <el-table-column label="门店通用" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.GlobalStores | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是属总部" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.HeadMark | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="120">
        <template slot-scope="scope">
          <span class="card-note" v-html="scope.row.CardNote"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="CardExistNumber"
        label="持卡人数"
        width="120"
      ></el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.CardState == 1">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        :width="cardState == 1 ? '150' : '100'"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            class="defuat-button"
            v-show="cardState == 1"
            type="primary"
            size="mini"
            @click="openSettingDialog(scope.row)"
          >
            设 置
          </el-button>

          <el-button
            class="defuat-button"
            v-show="cardState != 1"
            type="warning"
            size="mini"
            :disabled="restoreDisabled"
            @click="onDelAndResAndDisCard(scope.row.CardGuid, '恢复')"
          >
            恢 复
          </el-button>

          <el-popover
            popper-class="popper"
            placement="bottom"
            style="min-width: 0"
            trigger="hover"
          >
            <div class="popover-button-box">
              <el-button
                type="primary"
                size="mini"
                @click="onEditMemberCard(scope.row.CardGuid)"
              >
                修 改
              </el-button>
              <el-button
                type="danger"
                size="mini"
                :disabled="deleteDisabled"
                @click="onDelAndResAndDisCard(scope.row.CardGuid, '删除')"
              >
                删 除
              </el-button>
              <el-button
                type="info"
                size="mini"
                :disabled="disableDisabled"
                @click="onDelAndResAndDisCard(scope.row.CardGuid, '停用')"
              >
                停 用
              </el-button>
            </div>
            <el-button
              class="more"
              v-show="cardState == 1"
              size="mini"
              slot="reference"
              >更 多</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据表格 -->

    <!-- start 分页器 -->
    <div class="pagination-box">
      <el-button type="primary" size="small" @click="onChangePagination"
        >{{ !isPagination ? "" : "不" }}分页</el-button
      >
      <el-pagination
        background
        :page-sizes="[10, 20, 30, 40, 50, 10000]"
        :page-size="pageSize"
        :current-page="pageIndex"
        :layout="
          !isPagination ? 'total' : 'total, sizes, prev, pager, next, jumper'
        "
        :total="totalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- end 分页器 -->

    <!-- 新增会员卡对话框 -->
    <AddDialog ref="addDialog" @refreshData="initPageData"></AddDialog>

    <!-- 设置卡类型对话框 -->
    <SettingDialog ref="settingDialog" />

    <!-- 同步卡类型相关对象对话框 -->
    <CardTypeRelateDialog
      ref="CardTypeRelateDialog"
      @success="onShowSync"
    ></CardTypeRelateDialog>

    <!-- 同步会员卡对话框 -->
    <DfSyncStoreDialog
      ref="DfSyncStoreDialog"
      :syncFuction="synCardAndRefs"
    ></DfSyncStoreDialog>
  </div>
</template>

<script>
import card from "@/api/card.js";
import AddDialog from "./components/add-dialog.vue";
import SettingDialog from "./components/setting-dialog.vue";
import DiscPopover from "./components/disc-popover.vue";
import CardTypeRelateDialog from "./components/card-type-relate-dialog.vue";

export default {
  components: { AddDialog, SettingDialog, DiscPopover, CardTypeRelateDialog },

  data() {
    return {
      authType: "",
      tableMaxHeight: "", // 表格最大高度
      searchText: "", // 搜索栏关键词
      pageIndex: 1, // 页码
      pageSize: 10, // 页数
      totalRecord: 0, // 总页数
      cardState: 1, // 会员卡状态 1会员卡 2已删会员卡 3已禁用会员卡
      cardType: "储值卡", // 会员卡类型的文本
      natureNo: "0001", // 会员卡类型的id
      cardGuid: "", // 卡id
      tableData: [], // 会员卡列表
      cardNatureList: [], // 会员卡属性列表 （储值卡、疗程卡...）
      cardExtraList: [], // 会员卡额外信息列表
      cardSettingList: {}, // 所有下拉框数据列表
      loading: true, // 表格加载显示
      extraLoading: true, // 额外信息触发显示
      deleteDisabled: false, // 删除按钮禁用
      restoreDisabled: false, // 恢复按钮禁用
      disableDisabled: false, // 停用按钮禁用

      requestPowerParam: {
        // 控制权限的对话框
        show: false,
        key: "",
        code: "",
        power: "",
        callback: () => {},
      },
      selectItems: [],
      cardGuids: [],
      synRefsTypies: [],
      isPagination: true,
      isActiveCheck: false,
      allCheck: false,
      indeterminate: false,
    };
  },

  watch: {
    // 监听vue的$route路由事件
    $route: {
      async handler(to, from) {
        this.cardState = this.$route.query.cardState || 1;
        this.cardType = "储值卡";
        this.natureNo = "0001";
        this.resetPageData();
      },
      immediate: true, //第一次就执行,这个参数必须设置
    },
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.initCardSettingData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 52);
    },

    // 初始化页面表格数据
    async initPageData(itemGuids = []) {
      this.loading = true;
      this.tableData = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;

      try {
        let submitData = {
          page_index: this.pageIndex,
          page_size: this.pageSize,
          card_state: this.cardState,
          natureno: this.natureNo,
          word: this.searchText,
        };

        let { data } = await card.getCardMainList(submitData);
        let { cardMainList, cardNatureList, filter } = data;
        this.totalRecord = filter.TotalRecord;
        this.cardNatureList = cardNatureList;
        if (itemGuids.length > 0) {
          this.isActiveCheck = true;
          this.onSelectChange("all", cardMainList);
          return;
        } else if (this.selectItems.length > 0) {
          this.tableData = cardMainList;
          this.onRowSelection();
          return;
        }
        this.tableData = cardMainList;
        // console.log(this.cardMainList);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    // 初始化所有下拉框的数据列表
    async initCardSettingData(cardGuid = "") {
      let submitData = cardGuid ? { card_guid: cardGuid } : "";
      let { data } = await card.initCardSettingList(submitData);
      data.discMode[0].Name = "无";
      data.uniteIndexList[0].Name = "无";
      data.limitUnitsList[0].Name = "无";
      data.itemClassList.unshift({ ClassGuid: "", ClassName: "全部" });
      data.dutyList.unshift({ DutyNo: " ", DutyName: "不限" });
      this.cardSettingList = data;
      // console.log(data);
    },

    // 同步
    async synCardAndRefs(event, callback) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems));
      // console.log(event);
      // return new Promise(async (resolve, reject) => {
      try {
        let res = await card.synCardAndRefs(event.authKey, event.authCode, {
          SourceStoresGuid: window.sessionStorage.getItem("selectStoreGuid"),
          TargetStoresGuid: event.targetStoresGuid,
          SelectCardGuids: selectItems.map((e) => e.CardGuid),
          SynRefsTypies: this.synRefsTypies,
        });
        // await callback(res)
        // await resolve(res);
        return res;
      } catch (err) {
        console.log(err);
        return err;
        // await callback(err)
        // await reject(err);
      }
      // });
    },

    // 获取不同的卡类型数据
    onChangeCardType(event) {
      this.resetPageData();
    },

    // 多选框改变事件
    async onSelectChange(event = "", row) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems)),
        tableData = JSON.parse(JSON.stringify(row));
      this.loading = true;
      if (event == "all") {
        selectItems = [];
        await tableData.forEach((e) => {
          if (this.isActiveCheck) {
            e.isCheck = true;
            selectItems.push(e);
          } else {
            selectItems = [];
            e.isCheck = false;
          }
        });
        this.tableData = await tableData;
      } else {
        let index = await selectItems.findIndex(
          (e) => e.CardGuid == row.CardGuid
        );
        index > -1
          ? selectItems.splice(index, 1)
          : (selectItems = [...selectItems, row]);
        row.isCheck = !row.isCheck;
      }

      this.selectItems = await selectItems;
      await this.onAllSelection();
      await setTimeout(() => {
        this.loading = false;
      }, 200);
      // this.selectItems = [...this.selectItems, row]
      // console.log(this.isActiveCheck, this.allCheck, this.indeterminate);
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(async () => {
        let count = 0;
        // console.log(this.tableData, this.selectItems);
        await this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].CardGuid === item.CardGuid) {
              count++;
              // console.log(this.tableData[index], item);
              // this.$refs.multipleTable.toggleRowSelection(
              //   this.tableData[index],
              //   true
              // );
              this.tableData[index].isCheck = true;
              break;
            }
          }
        });

        if (count > 0) {
          await this.onAllSelection();
        } else {
          this.isActiveCheck = false;
          this.allCheck = false;
          this.indeterminate = false;
        }
      });
    },

    // 全选回显
    async onAllSelection() {
      this.isActiveCheck = (await this.selectItems.length) > 0 ? true : false;
      this.allCheck =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length == this.tableData.length
          ? true
          : false;
      this.indeterminate =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length != this.tableData.length
          ? true
          : false;
    },

    // 重置页面请求数据
    resetPageData() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.selectItems = [];
      this.initPageData();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },

    // 搜索会员卡
    onSearch() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.initPageData();
    },

    // 打开添加会员卡对话框
    onAddCard() {
      this.$refs.addDialog.openMemberCardDialog("", "添加");
    },

    // 打开同步卡类型相关对象对话框
    onShowRelate() {
      this.util.checkPower("1007", this.requestPowerParam, async () => {
        let arr = await this.selectItems.map((e) => e.CardGuid);
        if (!arr.length) {
          return this.$message.error("请先选择会员卡");
        }
        this.cardGuids = arr;
        await this.$refs.CardTypeRelateDialog.onShowDialog();
      });
      // this.$refs.CardTypeRelateDialog.onShowDialog()
    },

    // 打开同步对话框
    onShowSync(event) {
      // this.util.checkPower("1007", this.requestPowerParam, async () => {
      //   let arr = await this.selectItems.map((e) => e.CardGuid);
      //   if (!arr.length) {
      //     return this.$message.error("请先选择会员卡");
      //   }
      this.synRefsTypies = event;
      this.$refs.DfSyncStoreDialog.onShowDialog(this.cardGuids);
      // });
    },

    // 删除按钮点击事件
    async onDelAndResAndDisCard(cardGuid, typeText) {
      this.$confirm(`是否${typeText}该会员卡`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: typeText == "恢复" ? "" : "warning",
      }).then(async () => {
        try {
          let submitData = { card_guid: cardGuid };
          let data = {};

          if (typeText == "删除") {
            this.deleteDisabled = true;
            data = await card.deleteCard(submitData);
          } else if (typeText == "停用") {
            this.disableDisabled = true;
            data = await card.disabledCard(submitData);
          } else {
            this.restoreDisabled = true;
            data = await card.restoreCard(submitData);
          }

          let { errcode, errmsg } = data;

          if (errcode == 0) {
            this.$message.success(`会员卡${typeText}成功`);
            this.initPageData();
          } else {
            this.$message.error(errmsg);
          }
        } finally {
          this.deleteDisabled = false;
          this.disableDisabled = false;
          this.restoreDisabled = false;
        }
      });
    },

    // 打开会员卡设置对话框
    async openSettingDialog(event) {
      await this.initCardSettingData(event.CardGuid);
      await this.$refs.settingDialog.openSettingDialog(
        this.cardSettingList,
        event
      );
    },

    // 打开修改会员卡对话框
    onEditMemberCard(cardGuid) {
      this.$refs.addDialog.openMemberCardDialog(cardGuid, "修改");
    },

    onChangePagination() {
      this.handleSizeChange(this.isPagination ? 10000 : 10);
      this.isPagination = !this.isPagination;
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  .right {
    display: flex;
    align-items: center;
  }
  .el-input {
    width: 300px !important;
  }
  .el-button {
    margin-left: 10px;
  }
}

.popover-disc-item {
  display: flex;
  align-items: center;
  height: 40px;
}

.el-table {
  .image-text {
    display: flex;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 56px;
      margin-left: 10px;
      width: 200px;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .size {
        font-size: 10px;
      }
      i {
        color: #6aabdb;
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .el-image {
    width: 100px;
    height: 56px;
    text-align: center;
    line-height: 56px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    vertical-align: middle;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .card-note {
    display: block;
    width: 100px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-popover-more {
    width: 50%;
    color: #20a0ff;
  }
  .popover-table-body {
    padding: 10px;
  }
}

::v-deep .defuat-button {
  margin-right: 10px;
}

.popover-button-box {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin: 0 !important;

  ::v-deep .el-button {
    // width: 100px;
    margin: 5px 5px;
  }
}

.pagination-box {
  .flex-row;
  justify-content: flex-end;
  margin-top: 20px;

  .el-button {
    margin-right: 20px;
  }
}
</style>
