<template>
  <div class="inte-body">
    <!-- start 顶部tab栏 -->
    <div class="inte-top">
      <!-- 单选按钮 -->
      <el-radio-group
        size="small"
        v-model="inteType"
        @change="onInteTypeChange"
      >
        <el-radio-button
          v-for="item in inteTypeList"
          :key="item.id"
          :label="item.value"
          >{{ item.title }}</el-radio-button
        >
      </el-radio-group>

      <div class="seach-box">
        <!-- 已设置的项目 -->
        <el-checkbox
          v-show="inteType == 'inte_item'"
          v-model="checked"
          @change="onSelectInteItem"
          >已设置的项目</el-checkbox
        >

        <!-- 分类下拉框 -->
        <el-select
          v-show="inteType == 'inte_item'"
          v-model="selectClassGuid"
          size="small "
          placeholder="全部"
        >
          <el-option
            v-for="item in cardSettingList.itemClassList"
            :key="item.ClassGuid"
            :label="item.ClassName"
            :value="item.ClassGuid"
          >
          </el-option>
        </el-select>

        <!-- 搜索输入框 -->
        <el-input
          v-show="inteType == 'inte_item'"
          v-model="seachText"
          size="small "
          placeholder="搜索项目编号或项目名称"
        ></el-input>

        <!-- 查找按钮 -->
        <el-button
          v-show="inteType == 'inte_item'"
          type="primary"
          size="small "
          @click="onSeachInteItem"
          >查找</el-button
        >

        <div class="edit-box">
          <!-- 新增一行按钮 -->
          <el-button
            v-show="inteType == 'inte_keep' && editMode"
            size="small"
            @click="onAddNewRow"
            >新增一行</el-button
          >

          <!-- 编辑模式按钮 -->
          <el-button
            type="primary"
            size="small "
            @click="editMode = !editMode"
            >{{ editMode ? "关闭编辑" : "开启编辑" }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- end 顶部tab栏 -->

    <!-- start 项目分类设置的table模板 -->
    <el-table
      v-show="inteType == 'inte_class'"
      v-loading="loading"
      :data="tableData"
      height="560"
    >
      <el-table-column
        prop="ClassNo"
        label="分类编号"
        width="180"
      ></el-table-column>
      <el-table-column prop="" label="分类名称" width="180">
        <template slot-scope="scope">
          <span style="color: tomato;" v-show="scope.row.HeadMark">[总]</span>
          <span>{{ scope.row.ClassName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消费(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              type="number"
              v-model="scope.row.ClassExpeMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ClassExpeMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="积分(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              type="number"
              v-model="scope.row.ClassExpeInte"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ClassExpeInte || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            ref="deleteButton"
            type="danger"
            size="mini"
            :disabled="!scope.row.ScicGuid || deleteDisable"
            @click="onDeleteInteItem(scope.row.ScicGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目分类设置的table模板  -->

    <!-- start 项目特别设置的table模板 -->
    <el-table
      v-show="inteType == 'inte_item'"
      v-loading="loading"
      :data="tableData"
      height="540"
    >
      <el-table-column
        prop="ClassName"
        label="项目分类"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="ItemNo"
        label="项目编号"
        width="100"
      ></el-table-column>
      <el-table-column prop="" label="项目名称" width="180">
        <template slot-scope="scope">
          <span style="color: tomato;" v-show="scope.row.HeadMark">[总]</span>
          <span>{{ scope.row.ItemName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="ItemPrice"
        label="项目单价"
        width="80"
      ></el-table-column>
      <el-table-column label="消费(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              type="number"
              v-model="scope.row.ItemExpeMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ItemExpeMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="积分(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              type="number"
              v-model="scope.row.ItemExpeInte"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ItemExpeInte || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            :disabled="!scope.row.SciiGuid || deleteDisable"
            @click="onDeleteInteItem(scope.row.SciiGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目特别设置的table模板 -->

    <!-- start 项目特别设置的table模板 -->
    <el-table
      v-show="inteType == 'inte_keep'"
      v-loading="loading"
      :data="tableData"
      height="540"
    >
      <el-table-column label="开卡/充值">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-select
              size="small"
              v-model="scope.row.KeepType"
              @change="onSelectChange($event, scope.$index, 'KeepType')"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.keepTypeList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>
            {{
              scope.row.KeepType
                | valueAndNameChange(cardSettingList.keepTypeList)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="充值(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              v-model="scope.row.KeepMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.KeepMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="积分(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              v-model="scope.row.KeepInte"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.KeepInte || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column class-name="operate-box" label="操作">
        <template slot-scope="scope">
          <el-button
            v-show="!scope.row.ScikGuid"
            type="danger"
            size="mini"
            @click="onRemoveTableData(scope.$index)"
          >
            移除
          </el-button>

          <el-button
            v-show="scope.row.ScikGuid"
            type="danger"
            size="mini"
            :disabled="deleteDisable"
            @click="onDeleteInteItem(scope.row.ScikGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目特别设置的table模板 -->

    <!-- start 分页器 -->
    <el-pagination
      v-show="inteType != 'inte_class'"
      background
      :page-sizes="[12, 20, 25]"
      :page-size="pageSize"
      :current-page="pageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- start 腿部 -->
    <div class="inte-footer">
      <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
      <el-button
        size="mini"
        type="primary"
        :disabled="submitButton"
        @click="onSubmitFrom"
      >
        确 定
      </el-button>
    </div>
    <!-- end 腿部 -->
  </div>
</template>

<script>
import card from "@/api/card";
import utils from "@/utils/utils";
export default {
  props: {
    // 所有下拉框的数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      selectClassGuid: "", // 项目分类id
      seachText: "", // 搜索栏关键词文本
      inteType: "inte_class", // 当前项目设置类型
      inteTypeList: [
        // 顶部单选按钮的options列表
        { id: 1, title: "按项目分类设置", value: "inte_class" },
        { id: 2, title: "对某项目特别设置", value: "inte_item" },
        { id: 3, title: "开卡充值积分设置", value: "inte_keep" },
      ],
      tableData: [], // 表格数据列表
      copeTableData: [], // 表格数据拷贝列表
      inteEditFromList: {
        // 添加、修改提交表格
        CardGuid: "",
        EditList: [],
      },
      pageIndex: 1, // 页码
      pageSize: 12, // 页数
      totalRecord: 0, // 总页数
      editMode: false, // 编辑模式
      submitButton: false, // 提交按钮禁用
      deleteDisable: false, // 删除按钮禁用
      loading: true, // 表格加载
      checked: true, // 参与轮牌
    };
  },

  filters: {
    // 通用过滤器
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },
  },

  mounted() {
    // console.log(this.cardGuid);
  },

  methods: {
    // 初始化数据
    async initPageData(cardGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      this.loading = true;

      try {
        let submitData =
          this.inteType == "inte_item"
            ? {
                card_guid: this.cardGuid,
                class_guid: this.selectClassGuid,
                word: this.seachText,
                set: this.checked ? 1 : 0,
              }
            : {
                card_guid: this.cardGuid,
              };

        if (this.inteType == "inte_class") {
          let { data } = await card.getInteClassList(submitData);
          return (this.tableData = data);
        } else if (this.inteType == "inte_item") {
          let { data } = await card.getInteItemList(submitData);

          // this.tableData = data;
          this.copeTableData = data;
          this.totalRecord = data.length;

          // this.checked && this.inteType != "inte_class"
          //   ? this.onSelectInteItem()
          //   : null;
          return this.falsePage();
        } else {
          let { data } = await card.getInteKeepList(submitData);
          return (this.tableData = data);
        }
      } finally {
        this.loading = false;
      }
    },

    // 伪分页
    falsePage() {
      let start = (this.pageIndex - 1) * this.pageSize,
        end = this.pageIndex * this.pageSize;
      this.tableData = this.copeTableData.slice(start, end);
      // console.log(this.tableData);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.falsePage();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.recordModifiedTableData();
      this.falsePage();
    },

    // 记录被修改的表格数据
    recordModifiedTableData() {
      let params = this.tableData.filter((item) => {
        return item.Modified;
      });
      params.forEach((item2) => {
        this.copeTableData.filter((item3) => {
          item3.ItemGuid == item2.ItemGuid ? (item3 = item2) : null;
        });
      });
    },

    // 项目分类设置、项目特别设置、开卡充值积分设置menu切换
    onInteTypeChange(event) {
      this.inteType = event;
      this.pageIndex = 1;
      this.pageSize = 12;
      this.resetAllInteData();
      this.initPageData();
    },

    // 已设置项目过滤
    onSelectInteItem() {
      // this.checked
      //   ? ((this.copeTableData = this.tableData),
      //     (this.tableData = this.tableData.filter((item) => {
      //       return item.ScdiGuid;
      //     })))
      //   : (this.tableData = this.copeTableData);
      this.initPageData();
    },

    // 关键词、条件查询
    onSeachInteItem() {
      this.pageIndex = 1;
      this.pageSize = 12;
      this.initPageData();
    },

    // input变化事件
    onInputChange(index) {
      this.tableData[index].Modified = 1;
    },

    // select变化事件
    onSelectChange(event, index, fieldName) {
      this.tableData[index].Modified = 1;
    },

    // 添加一行
    onAddNewRow() {
      this.tableData.push({
        HeadMark: 0,
        KeepInte: 0,
        KeepMoney: 0,
        KeepType: 0,
        Modified: 0,
        ScikGuid: "",
        StoresGuid: "",
      });
    },

    // 移除一行
    onRemoveTableData(index) {
      this.tableData.splice(index, 1);
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllInteData();
      this.$emit("close");
    },

    // 重置表格
    resetAllInteData() {
      this.inteEditFromList = {
        CardGuid: "",
        EditList: [],
      };
      // this.selectClassGuid = "";
      // this.seachText = "";
      // this.inteType = "inte_class";
      this.editMode = false;
    },

    // 移除当前列数据事件
    onDeleteInteItem(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisable = true;

        try {
          let data = {};

          if (this.inteType == "inte_class") {
            let submitData = { scic_guid: id };
            data = await card.deleteInteClass(submitData);
          } else if (this.inteType == "inte_item") {
            let submitData = { scii_guid: id };
            data = await card.deleteInteItem(submitData);
          } else if (this.inteType == "inte_keep") {
            let submitData = { scik_guid: id };
            data = await card.deleteInteKeep(submitData);
          }

          let { errcode, errmsg } = data;

          await this.$nextTick(() => {
            if (errcode == 0) {
              this.$message.success("删除成功");
              this.resetAllInteData();
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          });
        } finally {
          this.deleteDisable = false;
        }
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom() {
      let params =
        this.inteType == "inte_item" ? this.copeTableData : this.tableData;
      params.filter(async (item) => {
        if (item.Modified) {
          let param = {
            ClassGuid: item.ClassGuid,
            ScicGuid: item.ScicGuid,
            ClassExpeInte: parseInt(item.ClassExpeInte),
            ClassExpeMoney: parseFloat(item.ClassExpeMoney).toFixed(2) * 1,
          };

          if (this.inteType == "inte_item") {
            param = {
              ItemGuid: item.ItemGuid,
              SciiGuid: item.SciiGuid,
              ItemExpeInte: parseInt(item.ItemExpeInte),
              ItemExpeMoney: parseFloat(item.ItemExpeMoney).toFixed(2) * 1,
            };
          } else if (this.inteType == "inte_keep") {
            param = {
              ScikGuid: item.ScikGuid,
              KeepType: item.KeepType,
              KeepInte: item.KeepInte,
              KeepMoney: item.KeepMoney,
            };
          }

          this.inteEditFromList.EditList.push(param);
        }
      });

      let modifiedCount = this.inteEditFromList.EditList.length;
      // console.log(this.inteEditFromList.EditList);
      if (modifiedCount) {
        this.$confirm(
          `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
          "保存提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            this.submitButton = true;

            this.inteEditFromList.CardGuid = this.cardGuid;
            try {
              let data = {};

              if (this.inteType == "inte_class") {
                data = await card.saveInteClass(this.inteEditFromList);
              } else if (this.inteType == "inte_item") {
                data = await card.saveInteItem(this.inteEditFromList);
              } else {
                data = await card.saveInteKeep(this.inteEditFromList);
              }

              let { errcode, errmsg } = data;

              if (errcode == 0) {
                this.$message.success("修改成功");
                // this.resetAllInteData();
                this.initPageData();
              }
            } finally {
              this.submitButton = false;
            }
          })
          .finally(() => {
            this.inteEditFromList = {
              CardGuid: "",
              EditList: [],
            };
            this.submitButton = false;
          });
      } else {
        this.onCloseDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.inte-body {
  .inte-top {
    margin-bottom: 20px;

    .seach-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .edit-box {
        position: absolute;
        right: 0;
      }

      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 200px !important;
      }

      > * {
        margin: 0 5px;
      }
    }
  }

  .operate-box {
    .el-button {
      margin: 0;
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .inte-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>
