<template>
  <div class="appo-body">
    <!-- start 顶部tab栏 -->
    <div class="appo-top">
      <div class="seach-box">
        <!-- 已设置的项目 -->
        <el-checkbox v-model="checked" @change="onSelectAppo"
          >已设置的项目</el-checkbox
        >

        <!-- 分类下拉框 -->
        <el-select v-model="selectClassGuid" size="small " placeholder="全部">
          <el-option
            v-for="item in cardSettingList.itemClassList"
            :key="item.ClassGuid"
            :label="item.ClassName"
            :value="item.ClassGuid"
          >
          </el-option>
        </el-select>

        <!-- 搜索输入框 -->
        <el-input
          v-model="seachText"
          size="small "
          placeholder="搜索项目编号或项目名称"
        ></el-input>

        <!-- 查找按钮 -->
        <el-button type="primary" size="small " @click="onSeachAppo"
          >查找</el-button
        >
      </div>
    </div>
    <!-- end 顶部tab栏 -->

    <!-- start 指定项目table -->
    <el-table v-loading="loading" :data="tableData" height="580">
      <el-table-column
        prop="ClassName"
        label="项目分类"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="ItemNo"
        label="项目编号"
        width="100"
      ></el-table-column>
      <el-table-column prop="" label="项目名称" width="180">
        <template slot-scope="scope">
          <span style="color: tomato;" v-show="scope.row.HeadMark">[总]</span>
          <span>{{ scope.row.ItemName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="ItemPrice"
        label="项目单价"
        width="180"
      ></el-table-column>
      <el-table-column label="标记">
        <template slot-scope="scope">
          {{ scope.row.ScaiGuid ? "已选择" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            :disabled="saveButton"
            @click="onSubmitFrom(scope.row)"
          >
            保存
          </el-button>
          <el-button
            type="danger"
            size="mini"
            :disabled="!scope.row.ScaiGuid || deleteDisable"
            @click="onDeleteAppo(scope.row.ScaiGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 指定项目table -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[12, 20, 25]"
      :page-size="pageSize"
      :current-page="pageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- start 腿部 -->
    <div class="appo-footer">
      <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
      <el-button
        size="mini"
        type="primary"
        :disabled="submitButton"
        @click="onCloseDialog"
      >
        确 定
      </el-button>
    </div>
    <!-- end 腿部 -->
  </div>
</template>

<script>
import card from "@/api/card";
export default {
  props: {
    // 所有下拉框的数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      selectClassGuid: "", // 项目分类id
      seachText: "", // 搜索栏关键词文本
      tableData: [], // 表格数据列表
      copyTableData: [], // 表格数据列表拷贝
      pageIndex: 1, // 页码
      pageSize: 12, // 页数
      totalRecord: 0, // 总页数
      editMode: false, // 编辑模式
      submitButton: false, // 提交按钮禁用
      saveButton: false, // 保存按钮禁用
      deleteDisable: false, // 删除按钮禁用
      loading: true, // 表格加载
      checked: true, // 参与轮牌
    };
  },

  mounted() {
    // console.log(this.cardGuid);
  },

  methods: {
    // 初始化数据
    async initPageData(cardGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      this.loading = true;

      try {
        let submitData = {
          card_guid: this.cardGuid,
          class_guid: this.selectClassGuid,
          word: this.seachText,
          set: this.checked ? 1 : 0,
        };

        let { data } = await card.getAppoItemList(submitData);

        this.copyTableData = data;
        this.falsePage(this.checked);
      } finally {
        this.loading = false;
      }
    },

    // 伪分页
    falsePage(event) {
      let start = (this.pageIndex - 1) * this.pageSize,
        end = this.pageIndex * this.pageSize;
      if (event) {
        let arr = this.copyTableData.filter((item) => item.ScaiGuid);
        this.tableData = arr.slice(start, end);
        this.totalRecord = arr.length;
      } else {
        this.tableData = this.copyTableData.slice(start, end);
        this.totalRecord = this.copyTableData.length;
      }

      // console.log(this.tableData);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.falsePage(this.checked);
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.falsePage(this.checked);
    },

    // 已设置项目过滤
    onSelectAppo(event) {
      this.pageIndex = 1;
      this.falsePage(event);
      // this.initPageData();
    },

    // 关键词、条件查询
    onSeachAppo() {
      this.pageIndex = 1;
      this.pageSize = 12;
      this.initPageData();
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllAppoData();
      this.$emit("close");
    },

    // 重置表格
    resetAllAppoData() {
      this.editMode = false;
      this.seachText = "";
      this.selectClassGuid = "";
      this.checked = true;
      this.pageIndex = 1;
      this.pageSize = 12;
    },

    // 移除当前列数据事件
    onDeleteAppo(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisable = true;

        try {
          let submitData = { scai_guid: id };
          let { errcode, errmsg } = await card.deleteAppoItem(submitData);

          await this.$nextTick(() => {
            if (errcode == 0) {
              this.$message.success("删除成功");
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          });
        } finally {
          this.deleteDisable = false;
        }
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom(event) {
      this.$confirm(`是否确定选择保存【${event.ItemName}】项目`, "保存提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.saveButton = true;

          try {
            let submitData = {
              CardGuid: this.cardGuid,
              EditList: [
                { ScaiGuid: event.ScaiGuid, ItemGuid: event.ItemGuid },
              ],
            };

            let { errcode, errmsg } = await card.saveAppoItem(submitData);

            if (errcode == 0) {
              this.$message.success("保存成功");
              this.initPageData();
            }
          } finally {
            this.saveButton = false;
          }
        })
        .finally(() => {
          this.saveButton = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.appo-body {
  .appo-top {
    margin-bottom: 20px;

    .seach-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .edit-box {
        position: absolute;
        right: 0;
      }

      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 200px !important;
      }

      > * {
        margin: 0 5px;
      }
    }
  }

  .operate-box {
    .el-button {
      margin: 0;
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .appo-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>
