<template>
  <div class="dona-money-body">
    <!-- start 顶部tab栏 -->
    <div class="dona-top">
      <!-- 编辑模式按钮 -->
      <el-button
        class="editButton"
        type="primary"
        size="small "
        @click="editMode = !editMode"
        >{{ editMode ? "关闭编辑" : "开启编辑" }}</el-button
      >

      <!-- 新增一行按钮 -->
      <el-button v-show="editMode" size="small" @click="addNewTableData"
        >添加新行</el-button
      >
    </div>
    <!-- end 顶部tab栏 -->

    <!-- start 赠送金额table -->
    <el-table v-loading="loading" :data="tableData" height="600">
      <el-table-column label="开始金额" width="120">
        <template slot-scope="scope">
          <span style="color: tomato;">{{ scope.row.HeadMark ? '[总]' : '' }}</span>
          <div v-show="editMode">
            <el-input
              v-model="scope.row.KeepStart"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.KeepStart || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="结束金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.KeepEnd"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.KeepEnd || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="开卡/充值">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.KeepType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.keepTypeList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.KeepType
                | valueAndNameChange(cardSettingList.keepTypeList)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="赠送模式">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.DonaType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.DonaType | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额(元)">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.DonaMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.DonaMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例(%)">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.DonaRatio"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.DonaRatio || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="button-box">
        <template slot-scope="scope">
          <el-button
            v-show="!scope.row.ScdmGuid"
            type="danger"
            size="mini"
            @click="removeTableData(scope.$index)"
          >
            移除
          </el-button>

          <el-button
            v-show="scope.row.ScdmGuid"
            :disabled="deleteDisable"
            type="danger"
            size="mini"
            @click="onDeleteDiscItem(scope.row.ScdmGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 赠送金额table -->

    <!-- start 腿部 -->
    <div class="setting-footer">
      <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
      <el-button
        ref="submitButton"
        size="mini"
        type="primary"
        @click="onSubmitFrom"
      >
        确 定
      </el-button>
    </div>
  </div>
  <!-- end 腿部 -->
</template>

<script>
import card from "@/api/card";
import utils from "@/utils/utils";
export default {
  props: {
    // 所有下拉框的所有数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      tableData: [], // 表格数据列表
      discClassEditFromList: {
        // 添加、修改提交表格
        CardGuid: "",
        EditList: [],
      },
      editMode: false, // 修改模式
      deleteDisable: false,
      loading: true, // 表格加载
    };
  },

  filters: {
    // 通用过滤器
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },
  },

  mounted() {
    // console.log(this.cardSettingList);
  },

  methods: {
    // 初始化数据
    async initPageData(cardGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      this.loading = true;
      let submitData = {
        card_guid: this.cardGuid,
      };

      let { data } = await card.getDonaMoneyList(submitData);
      this.tableData = data;
      this.loading = false;
      // console.log(data);
    },

    // 输入框内容变化事件
    onInputChange(index) {
      this.tableData[index].Modified = 1;
    },

    // 下拉框内容变化事件
    onSelectChange(event, index) {
      this.tableData[index].Modified = 1;
    },

    // 新增一行
    addNewTableData(index) {
      this.tableData.push({
        CardGuid: "",
        DonaMoney: 0,
        DonaRatio: 0,
        DonaType: 0,
        HeadMark: 0,
        KeepEnd: 0,
        KeepStart: 0,
        KeepType: 0,
        Modified: 0,
        ScdmGuid: "",
        StoresGuid: "",
      });
    },

    // 移除一行
    removeTableData(index) {
      this.tableData.splice(index, 1);
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllDiscData();
      this.$emit("close");
    },

    // 重置表格
    resetAllDiscData() {
      this.discClassEditFromList = {
        CardGuid: "",
        EditList: [],
      };
      this.editMode = false;
    },

    // 移除当前列数据事件
    onDeleteDiscItem(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let submitData = { scdm_guid: id };

        this.deleteDisable = true;

        let { errcode, errmsg } = await card.deleteDonaMoney(submitData);

        await this.$nextTick(() => {
          if (errcode == 0) {
            this.$message.success("项目删除成功");
            this.resetAllDiscData();
            this.initPageData();
          } else {
            this.$message.error(errmsg);
          }
          this.deleteDisable = false;
        });
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom() {
      let params = this.tableData;
      params.filter(async (item) => {
        if (item.Modified) {
          this.discClassEditFromList.EditList.push({
            ScdmGuid: item.ScdmGuid,
            KeepStart: parseFloat(item.KeepStart).toFixed(2) * 1,
            KeepEnd: parseFloat(item.KeepEnd).toFixed(2) * 1,
            KeepType: item.KeepType,
            DonaType: item.DonaType,
            DonaMoney: parseFloat(item.DonaMoney).toFixed(2) * 1,
            DonaRatio: parseInt(item.DonaRatio),
          });
        }
      });
      let modifiedCount = this.discClassEditFromList.EditList.length;
      // console.log(this.discClassEditFromList.EditList);
      if (modifiedCount) {
        this.$confirm(
          `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
          "保存提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            this.$refs.submitButton.disabled = await true;

            this.discClassEditFromList.CardGuid = this.cardGuid;
            try {
              let { errcode } = await card.saveDonaMoney(
                this.discClassEditFromList
              );

              if (errcode == 0) {
                this.$message.success("保存成功");
                this.initPageData();
              }
            } finally {
              this.$refs.submitButton.disabled = false;
            }
          })
          .finally(() => {
            this.discClassEditFromList = {
              CardGuid: "",
              EditList: [],
            };
            this.$refs.submitButton.disabled = false;
          });
      } else {
        this.onCloseDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dona-money-body {
  .dona-top {
    margin-bottom: 20px;

    .seach-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 200px;
      }

      > * {
        margin: 0 5px;
      }
    }
  }

  .el-table {
    ::v-deep .el-button {
      margin-left: 10px !important;
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .setting-footer {
    position: absolute;
    // display: flex;
    // justify-content: flex-end;
    // align-items: flex-end;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>